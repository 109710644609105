/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI CloudTune Live Monitoring Website
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Actuals
 */
export interface Actuals {
    /**
     * 
     * @type {string}
     * @memberof Actuals
     */
    'forecastProfileId': string;
    /**
     * 
     * @type {ActualsDatapoints}
     * @memberof Actuals
     */
    'datapointList': ActualsDatapoints;
}
/**
 * 
 * @export
 * @interface ActualsDatapoints
 */
export interface ActualsDatapoints {
    /**
     * 
     * @type {Array<number>}
     * @memberof ActualsDatapoints
     */
    'd': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ActualsDatapoints
     */
    'v': Array<number>;
}
/**
 * 
 * @export
 * @interface Announcement
 */
export interface Announcement {
    /**
     * 
     * @type {string}
     * @memberof Announcement
     */
    'text': string;
}
/**
 * 
 * @export
 * @interface ChartData
 */
export interface ChartData {
    /**
     * 
     * @type {string}
     * @memberof ChartData
     */
    'forecastProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof ChartData
     */
    'name': string;
    /**
     * 
     * @type {Array<HorizontalAnnotation>}
     * @memberof ChartData
     */
    'horizontalAnnotations': Array<HorizontalAnnotation>;
    /**
     * 
     * @type {Array<VerticalAnnotation>}
     * @memberof ChartData
     */
    'verticalAnnotations': Array<VerticalAnnotation>;
    /**
     * 
     * @type {ChartDatapoints}
     * @memberof ChartData
     */
    'datapoints'?: ChartDatapoints;
    /**
     * 
     * @type {boolean}
     * @memberof ChartData
     */
    'inPeak': boolean;
}
/**
 * 
 * @export
 * @interface ChartDatapoints
 */
export interface ChartDatapoints {
    /**
     * 
     * @type {Array<number>}
     * @memberof ChartDatapoints
     */
    'dateDatapoints': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChartDatapoints
     */
    'p95ValueDatapoints': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChartDatapoints
     */
    'p50ValueDatapoints': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChartDatapoints
     */
    'p05ValueDatapoints': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ChartDatapoints
     */
    'aDatapoints': Array<number>;
}
/**
 * 
 * @export
 * @interface ForecastProfile
 */
export interface ForecastProfile {
    /**
     * 
     * @type {string}
     * @memberof ForecastProfile
     */
    'forecastProfileId': string;
    /**
     * 
     * @type {string}
     * @memberof ForecastProfile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ForecastProfile
     */
    'region': string;
}
/**
 * 
 * @export
 * @interface HorizontalAnnotation
 */
export interface HorizontalAnnotation {
    /**
     * 
     * @type {string}
     * @memberof HorizontalAnnotation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof HorizontalAnnotation
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof HorizontalAnnotation
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof HorizontalAnnotation
     */
    'startTime': number;
    /**
     * 
     * @type {number}
     * @memberof HorizontalAnnotation
     */
    'endTime': number;
    /**
     * 
     * @type {number}
     * @memberof HorizontalAnnotation
     */
    'version'?: number;
}
/**
 * 
 * @export
 * @interface VerticalAnnotation
 */
export interface VerticalAnnotation {
    /**
     * 
     * @type {string}
     * @memberof VerticalAnnotation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof VerticalAnnotation
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof VerticalAnnotation
     */
    'startTime': number;
    /**
     * 
     * @type {number}
     * @memberof VerticalAnnotation
     */
    'endTime': number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary retrieve actuals of specified ids starting at parameter startDate
         * @param {Array<string>} ids profile ids of desired charts
         * @param {number} resolution desired resolution for selected actuals
         * @param {string} startTime the first date of actuals to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActuals: async (ids: Array<string>, resolution: number, startTime: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('getActuals', 'ids', ids)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('getActuals', 'resolution', resolution)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getActuals', 'startTime', startTime)
            const localVarPath = `/actuals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Summary text for banner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncement: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/announcement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieve charts of specified ids from startDate to endDate
         * @param {Array<string>} ids profile ids of desired charts
         * @param {number} resolution desired resolution for selected actuals
         * @param {string} startTime the first date of datapoints to query
         * @param {string} endTime the late date of datapoints to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCharts: async (ids: Array<string>, resolution: number, startTime: string, endTime: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ids' is not null or undefined
            assertParamExists('getCharts', 'ids', ids)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('getCharts', 'resolution', resolution)
            // verify required parameter 'startTime' is not null or undefined
            assertParamExists('getCharts', 'startTime', startTime)
            // verify required parameter 'endTime' is not null or undefined
            assertParamExists('getCharts', 'endTime', endTime)
            const localVarPath = `/charts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ids) {
                localVarQueryParameter['ids'] = ids;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists all available profiles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProfiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary retrieve actuals of specified ids starting at parameter startDate
         * @param {Array<string>} ids profile ids of desired charts
         * @param {number} resolution desired resolution for selected actuals
         * @param {string} startTime the first date of actuals to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActuals(ids: Array<string>, resolution: number, startTime: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Actuals>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActuals(ids, resolution, startTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Summary text for banner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnouncement(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnouncement(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieve charts of specified ids from startDate to endDate
         * @param {Array<string>} ids profile ids of desired charts
         * @param {number} resolution desired resolution for selected actuals
         * @param {string} startTime the first date of datapoints to query
         * @param {string} endTime the late date of datapoints to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCharts(ids: Array<string>, resolution: number, startTime: string, endTime: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChartData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCharts(ids, resolution, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lists all available profiles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listProfiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForecastProfile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listProfiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary retrieve actuals of specified ids starting at parameter startDate
         * @param {Array<string>} ids profile ids of desired charts
         * @param {number} resolution desired resolution for selected actuals
         * @param {string} startTime the first date of actuals to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActuals(ids: Array<string>, resolution: number, startTime: string, options?: any): AxiosPromise<Array<Actuals>> {
            return localVarFp.getActuals(ids, resolution, startTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Summary text for banner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncement(options?: any): AxiosPromise<object> {
            return localVarFp.getAnnouncement(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieve charts of specified ids from startDate to endDate
         * @param {Array<string>} ids profile ids of desired charts
         * @param {number} resolution desired resolution for selected actuals
         * @param {string} startTime the first date of datapoints to query
         * @param {string} endTime the late date of datapoints to query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCharts(ids: Array<string>, resolution: number, startTime: string, endTime: string, options?: any): AxiosPromise<Array<ChartData>> {
            return localVarFp.getCharts(ids, resolution, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists all available profiles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProfiles(options?: any): AxiosPromise<Array<ForecastProfile>> {
            return localVarFp.listProfiles(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary retrieve actuals of specified ids starting at parameter startDate
     * @param {Array<string>} ids profile ids of desired charts
     * @param {number} resolution desired resolution for selected actuals
     * @param {string} startTime the first date of actuals to query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getActuals(ids: Array<string>, resolution: number, startTime: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getActuals(ids, resolution, startTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Summary text for banner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnnouncement(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAnnouncement(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieve charts of specified ids from startDate to endDate
     * @param {Array<string>} ids profile ids of desired charts
     * @param {number} resolution desired resolution for selected actuals
     * @param {string} startTime the first date of datapoints to query
     * @param {string} endTime the late date of datapoints to query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCharts(ids: Array<string>, resolution: number, startTime: string, endTime: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCharts(ids, resolution, startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists all available profiles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listProfiles(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listProfiles(options).then((request) => request(this.axios, this.basePath));
    }
}

