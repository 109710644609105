export const ORDERED_REGION_RANKING = toRankMap(
    ["NA", "EU", "EUIN", "FE", "US", "CA", "MX", "BR", "DE", "UK", "ES", "IT", "FR", "TR", "AE", "NL",
        "SA", "SE", "PL", "EG", "BE", "IN", "ZA", "JP", "AU", "SG"])

export function sortByExplicitRegionOrder(profileIds, profileToRegionMapping) {
    profileIds.sort((profileA, profileB) => {
        return (ORDERED_REGION_RANKING.get(profileToRegionMapping.get(profileA)) || -1) -
            (ORDERED_REGION_RANKING.get(profileToRegionMapping.get(profileB)) || -1)
    })
}

export function sortBySelectedRegionAndSelectedGroup(profileIds: string[],
                                                     selectedGroups: string[],
                                                     profileToRegionMap: Map<string, string>,
                                                     profileToGroup: Map<string, string>,
                                                     selectedRegion?: string[],
                                                     ) {
    const regionOrdering = selectedRegion ? toRankMap(selectedRegion) : ORDERED_REGION_RANKING
    profileIds.sort((profileA, profileB) =>
        compareByRegionThenGroup(
            profileA,
            profileB,
            regionOrdering,
            toRankMap(selectedGroups),
            profileToRegionMap,
            profileToGroup)
    )
}


function toRankMap(orderedAttributes: string[]): Map<string, number> {
    return new Map(orderedAttributes.map((val, idx) => [val, idx]));
}

function compareByRegionThenGroup(profileA, profileB,
                                  selectedRegionsRanking: Map<string, number>,
                                  selectedGroupsRanking: Map<string, number>,
                                  profileToRegion: Map<string, string>,
                                  profileToGroup: Map<string, string>) {
    const result = getRank(profileA, selectedRegionsRanking, profileToRegion) -
        getRank(profileB, selectedRegionsRanking, profileToRegion)
    if (result !== 0) {
        return result;
    }
    return getRank(profileA, selectedGroupsRanking, profileToGroup) -
        getRank(profileB, selectedGroupsRanking, profileToGroup)
}

function getRank(profile, attrRank: Map<string, number>, profileToAttrLookup: Map<string, string>) {
    const attr = profileToAttrLookup.get(profile);
    if (!attr) {
        throw Error(`Profile ${profile} not found in attribute lookup: ${Array.from(profileToAttrLookup.keys())}`)
    }
    const rank = attrRank.get(attr)
    if (rank === undefined) {
        throw Error(`Attribute ${attr} not found in ranking lookup: ${Array.from(attrRank.keys())}`)
    }
    return rank;
}
