import React from "react";
import ForecastChart, {ForecastChartProps} from "../chart/ForecastChart";
import {CollectionCardProps} from "./ChartCollection";

export interface StaticFlatChartContainerProps {
    forecastProfileId: string
}
export interface FlatChartContainerProps extends StaticFlatChartContainerProps, CollectionCardProps, ForecastChartProps {}

export default function FlatChartContainer(props: FlatChartContainerProps) {
    return (
            <ForecastChart
                {...props}
                chartData={props.chartDataMap.get(props.forecastProfileId)}
            />
    )
}