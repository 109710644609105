import React from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import {ColumnLayout, ExpandableSection} from "@amzn/awsui-components-react";
import {ParentToChildMapping} from "../../config/ProfileGroupMappings";
import {ChartData} from "../../live-monitoring/generated-src";
import ForecastChart, {ForecastChartProps} from "../chart/ForecastChart";
import {ActionableCollectionCardProps, CollectionCardProps} from "./ChartCollection";

export interface StaticExpandableChartContainerProps {
    profileMapping: ParentToChildMapping
}

export interface ExpandableChartContainerProps extends StaticExpandableChartContainerProps, CollectionCardProps, ActionableCollectionCardProps, ForecastChartProps {
}

export default function ExpandableChartContainer(props: ExpandableChartContainerProps) {


    const parent: ChartData | undefined = props.chartDataMap.get(props.profileMapping.parentId)
    const childIds = props.profileMapping.childId

    const childCharts: Map<String, ChartData |undefined> = new Map(childIds.map(id => [id, props.chartDataMap.get(id)]))

    return (
        <Container
            footer={
                props.profileMapping.childId.length !== 0 &&
                <ExpandableSection header="Expand to Individual Forecast Profiles"
                                   variant="footer"
                                   onChange={expanded => {
                                       if (expanded) {
                                           props.sortByExplicitRegion(childIds)
                                           props.addActiveCharts(childIds)
                                       } else {
                                           props.removeCharts(childIds)
                                       }
                                   }}>
                    <ColumnLayout columns={2} variant={"text-grid"}>
                        {
                            childIds
                                .filter(id => {
                                    return childCharts.get(id)})
                                .map(id => {
                                return (
                                    <ForecastChart
                                        {...props}
                                        chartData={childCharts.get(id)}
                                        key={id}/>
                                )
                            })}
                    </ColumnLayout>
                </ExpandableSection>
            }>
            <ForecastChart
                {...props}
                chartData={parent}
            />
        </Container>
    )
}