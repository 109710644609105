import "./LegentPluginStyle.scss"

export default function legendFormatterPlugin() {
    let toolTipEl, overEl;

    function init(u, opts) {
        const legendEl = u.root.querySelector(".u-legend");
        //Ensure legend gets grid sizing
        legendEl.classList.remove("u-inline");

        toolTipEl = legendEl.cloneNode(true);
        toolTipEl.classList.add("u-hide-tooltip-magnifier")

        // add legend into plot
        overEl = u.over;
        overEl.appendChild(toolTipEl);

        // show/hide tooltip on enter/exit
        overEl.addEventListener("mouseenter", () => {
            toolTipEl.classList.replace("u-hide-tooltip-magnifier","u-show-tooltip-magnifier")
        });
        overEl.addEventListener("mouseleave", () => {
            toolTipEl.classList.replace("u-show-tooltip-magnifier", "u-hide-tooltip-magnifier")
        });
    }

    function update(u) {
        //Move tooltip to cursor
        const {left, top} = u.cursor;
        // If tooltip gets off the screen when cursor is near the end of graph, move tooltip from right of the cursor to the left
        if (left + toolTipEl.offsetWidth + overEl.getBoundingClientRect().left > window.innerWidth) {
            toolTipEl.style.transform = "translate(" + (left-toolTipEl.offsetWidth) + "px, " + top + "px)";
        } else {
            toolTipEl.style.transform = "translate(" + left + "px, " + top + "px)";
        }

        //Set values in tooltip legend
        const toolTipChildren = toolTipEl.querySelectorAll(".u-series")
        //Want to reverse because dom elements are in reverse order
        for (let i = 0; i < u.legend.values.length; i++) {
            toolTipChildren[i].querySelector(".u-value").textContent = u.legend.values[i]._;
        }
    }


    return {
        hooks: {
            init: init,
            setCursor: update,
        }
    };
}

