import * as React from "react";
import {Tabs} from "@amzn/awsui-components-react";
import ForecastDimensionDropdown, {ForecastDimensionDropdownProps} from "./ForecastDimensionDropdown";
import IndividualForecastSearch, {IndividualForecastSearchProps} from "./IndividualForecastSearch";
import {getAppSetting} from "../../config/AppSettings";

export interface SearchContainerProps {
    forecastDimensionDropdown?: ForecastDimensionDropdownProps
    individualForecastSearchProps?: IndividualForecastSearchProps
}

export default function SearchContainer(props: SearchContainerProps) {
    const [activeTabId, setActiveTabId] = React.useState(props.individualForecastSearchProps ? 'individualSearch' : 'dimensionDropdown');

    const tabs = [
        {
            label: 'Forecast Profile Group',
            id: 'dimensionDropdown',
            content: (
                <ForecastDimensionDropdown
                    {...props.forecastDimensionDropdown}
                />)
        },
        {
            label: 'Individual Forecast Search',
            id: 'individualSearch',
            content: <IndividualForecastSearch {...props.individualForecastSearchProps}/>
        }
    ]

    return (
        <Tabs
            tabs={tabs}
            activeTabId={activeTabId}
            onChange={({detail}) => setActiveTabId(detail.activeTabId)}
        />)
}

