import ChartCollection from "../../components/chart-containers/ChartCollection";
import React, {useContext, useMemo} from "react";
import ExpandableChartContainer, {
    ExpandableChartContainerProps,
    StaticExpandableChartContainerProps
} from "../../components/chart-containers/ExpandableChartContainer";
import {ParentToChildMapping, ProfileGroup} from "../../config/ProfileGroupMappings";
import {useLocation} from "react-router-dom";
import {GroupsContext, ProfileContext} from "../../context/ForecastProfileContext";
import {Spinner} from "@amzn/awsui-components-react";
import FlatChartContainer, {
    FlatChartContainerProps,
    StaticFlatChartContainerProps
} from "../../components/chart-containers/FlatChartContainer";
import DualExpandableChartContainer, {
    DualExpandableChartProps,
    StaticDualExpandableChartProps
} from "../../components/chart-containers/DualExpandableChartContainer";
import {splitArray} from "../../utils/arrayUtils";
import {
    sortBySelectedRegionAndSelectedGroup
} from "../../utils/sortUtils";

export interface FilterUrlParams {
    groups: string[],
    regions: string[]
}

//Use links to determine which things to push
export default function FilterContent() {
    const groupMapping = useContext(GroupsContext)
    const profiles = useContext(ProfileContext);

    const profileToRegionMapping: Map<string, string> = useMemo(() => {
            return profiles.reduce(
                (profileToRegionMap, profile) => {
                    profileToRegionMap.set(profile.forecastProfileId, profile.region)
                    return profileToRegionMap
                }, new Map<string, string>)
        }, [profiles]
    )


    const {search} = useLocation();
    const pathParams = new URLSearchParams(search)


    if (profiles.length === 0) {
        return (<Spinner size="large"/>)
    }

    var selectedGroups = Array.from(new Set(pathParams.get("groups")?.split(",")))
    if (!selectedGroups || selectedGroups.length === 0) {
        selectedGroups = [ProfileGroup.PhysicalOrderRate, ProfileGroup.PostOPFRWTraffic]
    }
    const selectedRegions = Array.from(new Set(pathParams.get("regions")?.split(","))) || []
    let chartContainerProperties: [
        containerDefinition:
            ((componentProp: ExpandableChartContainerProps) => JSX.Element) |
            ((componentProp: FlatChartContainerProps) => JSX.Element) |
            ((componentProp: DualExpandableChartProps) => JSX.Element)
        ,
        componentProps: StaticDualExpandableChartProps[] | StaticExpandableChartContainerProps[] | StaticFlatChartContainerProps[],
        activeProfileIds: string[],
        cardsPerRow: number]
    if (selectedGroups.length > 0 && selectedRegions.length === 0) {
        chartContainerProperties = generateExpandableCharts(selectedGroups, groupMapping, profileToRegionMapping)
    } else {
        chartContainerProperties = generateFlattenedCharts(
            selectedGroups,
            groupMapping,
            selectedRegions,
            profileToRegionMapping,
        )
    }

    const [chartComponentFactory, chartComponentProps, activeProfileIds, cardsPerRow] = chartContainerProperties

    const searchContainerProps = {
        forecastDimensionDropdown: {
            selectedGroups: selectedGroups,
            selectedRegions: selectedRegions
        }
    }


    return (
        <ChartCollection
            searchContainerProps={searchContainerProps}
            initialActiveProfiles={activeProfileIds}
            containerDefinition={chartComponentFactory}
            staticContainerProps={chartComponentProps}
            addDynamicProfileSetting={true}
            cardsPerRow={cardsPerRow}/>
    )

}

function getSelectedProfilesFromGroup(selectedGroups: string[], groupMapping: Map<ProfileGroup, ParentToChildMapping[]>) {
    const selectedProfilesFromGroup: ParentToChildMapping[] = []


    selectedGroups.forEach(group => {
        const values = groupMapping.get(group as ProfileGroup)
        if (values !== undefined) {
            selectedProfilesFromGroup.push(...values);
        }
    })
    return selectedProfilesFromGroup
}

function generateExpandableCharts(
    selectedGroups: string[],
    groupMapping: Map<ProfileGroup, ParentToChildMapping[]>,
    profileToRegionMapping: Map<string, string>):
    [
        componentFactory: ((componentProp: ExpandableChartContainerProps) => JSX.Element) |
            ((componentProp: DualExpandableChartProps) => JSX.Element),
        staticComponentProps: StaticExpandableChartContainerProps[] | StaticDualExpandableChartProps[],
        activeProfiles: string[],
        cardsPerRow: number
    ] {
    if (selectedGroups.length % 2 == 0) {
        //Get partitioned groups
        const staticContainerProps: StaticDualExpandableChartProps[] = []
        const activeProfiles: string[] = []
        const profileToSelectedGroup = new Map<string, string>
        const parentIdToParentChildMapping = new Map<string, ParentToChildMapping>()
        selectedGroups.forEach(group => {
            const selectedProfileMappings = groupMapping.get(group as ProfileGroup);
            selectedProfileMappings?.forEach(profileMapping => {
                activeProfiles.push(profileMapping.parentId)
                parentIdToParentChildMapping.set(profileMapping.parentId, profileMapping);
                profileToSelectedGroup.set(profileMapping.parentId, group)
            })
        })
        sortBySelectedRegionAndSelectedGroup(activeProfiles, selectedGroups, profileToRegionMapping, profileToSelectedGroup)
        const pairedGroupings = splitArray(activeProfiles, 2);
        //for each region listed return left mapping and right mapping properties
        pairedGroupings.forEach((grouping) => {
            const leftMapping = parentIdToParentChildMapping.get(grouping[0] as ProfileGroup)
            const rightMapping = parentIdToParentChildMapping.get(grouping[1] as ProfileGroup)
            staticContainerProps.push(
                {
                    leftMapping,
                    rightMapping
                });
        })
        return [componentProp =>
            <DualExpandableChartContainer {...componentProp}/>, staticContainerProps, activeProfiles, 1]

    } else {
        const selectedProfileMappings = getSelectedProfilesFromGroup(selectedGroups, groupMapping)
        const staticContainerProps: StaticExpandableChartContainerProps[] = []
        const activeProfiles: string[] = []
        selectedProfileMappings.forEach(profileMapping => {
            staticContainerProps.push({profileMapping})
            activeProfiles.push(profileMapping.parentId)
        })
        return [componentProp =>
            <ExpandableChartContainer {...componentProp}/>, staticContainerProps, activeProfiles, 1]
    }

}

function generateFlattenedCharts(
    selectedGroups: string[],
    groupToProfileMapping: Map<ProfileGroup, ParentToChildMapping[]>,
    selectedRegions: string[],
    profileToRegionMapping: Map<string, string>):
    [
        componentFactory: (componentProp: FlatChartContainerProps) => JSX.Element,
        staticComponentProps: StaticFlatChartContainerProps[],
        activeProfiles: string[],
        cardsPerRow: number
    ] {
    const activeProfiles: string[] = []
    const profileToSelectedGroup = new Map<string, string>

    //Filter group mappings to only have selected region
    selectedGroups.forEach(group => {
        const selectedProfileMappings = groupToProfileMapping.get(group as ProfileGroup);
        selectedProfileMappings?.forEach(profileMappings => {
            selectedRegions.forEach(region => {
                if (profileToRegionMapping.get(profileMappings.parentId) === region) {
                    activeProfiles.push(profileMappings.parentId)
                    profileToSelectedGroup.set(profileMappings.parentId, group)
                }
                profileMappings.childId.forEach(child => {
                    if (profileToRegionMapping.get(child) === region) {
                        activeProfiles.push(child)
                        profileToSelectedGroup.set(child, group)
                    }
                })
            })
        })
    })


    //Sort profiles by selected region, then selected group
    sortBySelectedRegionAndSelectedGroup(
        activeProfiles,
        selectedGroups,
        profileToRegionMapping,
        profileToSelectedGroup,
        selectedRegions,
    )

    const containerProps = activeProfiles.map(profile => {
        return {forecastProfileId: profile}
    })

    return [componentProp => <FlatChartContainer {...componentProp}/>, containerProps, activeProfiles, 2]
}









