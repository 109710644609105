import * as React from "react";
import {Alert} from "@amzn/awsui-components-react";

export default function UnauthenticatedPage() {

    return (
        <Alert
            statusIconAriaLabel="Error"
            type="error"
            header="Unable to authenticate user">
            If you are using the AEA mobile app on IPhone, please click on the settings gear on the top right and
            click on "Allow Cross-Website Tracking" to enable cookies for authentication
        </Alert>);
}
