import {getAppSetting} from "./AppSettings";

export enum ProfileGroup {
    PhysicalOrderRate = "Physical-Order-Rate",
    PreOPFRWTraffic = "pre-OPF-RW-Traffic",
    PostOPFRWTraffic = "post-OPF-RW-Traffic",
    ShoppingPortalTotalTraffic = "Shopping-Portal-Total-Traffic",
    ShoppingPortalSearchTraffic = "Shopping-Portal-Search-Traffic",
    ShoppingPortalGatewayTraffic = "Shopping-Portal-Gateway-Traffic",
    ShoppingPortalDetailTraffic = "Shopping-Portal-Detail-Traffic",
    DatapathResponder = "Datapath-Responder",
    SharedHttp = "Shared-HTTP",
    AAPIService = "AAPI-Service",
    AmazonAPIAPIDeals = "AmazonAPI-API-Deals",
    AmazonAPIAPINonDeals = "AmazonAPI-API-Non-Deals",
    AmazonAPIExternal = "AmazonAPI-External",
    AmazonAPIExternalAPIDeals = "AmazonAPI-External-API-Deals",
    AmazonAPIExternalAPIOthers= "AmazonAPI-External-API-Others",
    AmazonAPIProductV2="AmazonAPI-API-ProductV2",
    AmazonAPICanonicalProductV2="AmazonAPI-API-CanonicalProductV2",
    AmazonAPICustomerContext= "AmazonAPI-API-CustomerContext",
    AmazonAPIBuyingOptionsContextV2 = "AmazonAPI-API-BuyingOptionsContextV2",

    AmazonAPIOfferComparisonExperience = "AmazonAPI-API-OfferComparisonExperience",
    AmazonAPIOthers = "AmazonAPI-API-Others",

    AmazonApiDatapath = "AmazonAPI-Datapath",
    AmazonLastMile = "AmazonLastMile",
    AFTInboundReceive = "AFT-Inbound-Receive-Units",
    AFTInboundTransship = "AFT-Inbound-Transship-Inventory",
    AFTOutboundTransship = "AFT-Outbound-Transship-Inventory",
    AFTOutboundCustomerShip = "AFT-Outbound-Customer-Ship-Units"
}


export interface ParentToChildMapping {
    parentId: string,
    childId: string[]

}

const ALL_MAPPINGS = new Map([
    [ProfileGroup.PhysicalOrderRate, [
        //Physical-Order-Rate-NA
        {
            parentId: "9afa1c06-a780-449f-b3a5-f624c935934f",
            childId: [
                "1200001",
                "1200008",
                "5ddb2b2f-cedc-4c22-bd90-b5a747e3e7fd",
                "1f4f09cf-36d4-4b5c-be5e-bdedf1108679",
            ]
        },
        //Physical-Order-Rate-EUIN
        {
            parentId: "9c24126d-b298-4d7c-bd0c-743856a96214",
            childId: [
                "2eb66184-a13d-431f-9388-b8618fdc6f95",
                "1200002",
                "1200003",
                "1200004",
                "1200006",
                "1200007",
                "56070ac4-7114-4a49-a7a4-1b3cbb9ddaa6",
                "96933d0a-2442-481f-bbd0-2000cfae529e",
                "6b52d5ab-062e-4316-8d99-d06f0ed9a7e6",
                "834ce5a4-c6e0-495d-92cf-794361347ded",
                "bf206a8c-9f75-4518-a861-c9d2475a0b1b",
                "b709ed14-0247-4186-9993-2d4cf44502e8",
                "524bd2ab-b49f-4391-b6f0-1cf1f4e1f979",
                "1200010",
                "3ae8cb0e-f86c-406f-be0a-b3802bd545ae",
                "cfaf36e7-ffa0-4706-9021-0e24b1d3c985"
            ]
        },
        //Physical-Order-Rate-FE
        {
            parentId: "947953cb-b793-4b07-9e19-cf1bf1964963",
            childId: [
                "1200005",
                "47d64fc5-f66b-400a-875d-a6e7222e7577",
                "1e661dcd-7612-4de7-a398-cf41023b1629"
            ]
        },
    ]],
    [ProfileGroup.PreOPFRWTraffic, [
        //pre-OPF-RW-Traffic-NA
        {
            parentId: "1a3db591-1aa1-4e83-a219-c0b53b277afc",
            childId: [
                "1200011",
                "1200039",
                "1200502",
                "1200501"
            ]
        },
        //pre-OPF-RW-Traffic-EUIN
        {
            parentId: "05e8de45-cebf-4f40-89c1-ead201c8431f",
            childId: [
                "ced16a6a-6dc2-4abb-b197-bea9587b0890",
                "1200019",
                "1200015",
                "1200031",
                "1200035",
                "1200023",
                "8a163489-2bf8-4c66-a01b-025fdec9570f",
                "86bec86f-08f5-411e-964c-aa62963e1798",
                "1200503",
                "7c365de3-2dec-4a9c-ae50-f7467656833b",
                "824bff95-c26a-472f-8dd3-6e530988bbd1",
                "5ae25aa0-a9e4-438b-8993-56b1cd51e07c",
                "6d45050b-1536-4718-bc58-a80e64f4aab2",
                "4ca852e9-0577-4924-9171-ca387b1f97f6",
                "1200047"
            ]
        },
        //pre-OPF-RW-Traffic-FE
        {
            parentId: "c4e2cbe0-2185-4711-a243-b24c56f5a5ba",
            childId: [
                "1200027",
                "e0209dd9-46ac-47c4-88ed-d0c582ccdc6a",
                "c38a45e0-2862-4a76-b45e-301102df30a4"
            ]
        },
    ]],
    [ProfileGroup.PostOPFRWTraffic, [
        //post-OPF-RW-Traffic-NA
        {
            parentId: "1e4e79f2-17da-4b47-9a74-fd22df6499f3",
            childId: [
                "9df578ec-a113-41d8-96ee-8f6878249ac8",
                "06fa82bf-711a-4e63-9e14-456a97cef416",
                "879e9dd9-4a4f-45f5-b47c-944dca730d38",
                "9365ddb0-d009-4613-ad2d-47879be27750"
            ]
        },
        //post-OPF-RW-Traffic-EUIN
        {
            parentId: "61db53c6-4633-4425-a790-bf7c433328c8",
            childId: [
                "19e9d733-ab74-4d8b-9055-7610317bf665",
                "c1796b10-b22c-4042-bfbf-f58aa1a6e275",
                "a2e91c8a-920c-47e0-9e67-9d3ab65493fa",
                "818cb86b-2211-4054-8c08-1735f1f1f21e",
                "9ca74f9e-661d-4adf-aad6-23d53999cd9c",
                "802a4ec2-306a-412b-8153-f70de6bf5439",
                "6d2be124-175c-4cb6-af52-52264ec124a6",
                "0cf99215-1b48-45cd-8bc6-1287603e5623",
                "07679240-da34-497a-ab8d-34dc34ea637a",
                "b4c6c4d7-a7d2-438a-b601-bbf18dc5c4ca",
                "f0fe6898-685e-4f78-a124-58ee31839aa8",
                "68ca1403-3455-4262-98c8-3735bd9664aa",
                "2c97dedf-6245-4c80-bc6d-4e06e48a5d5e",
                "c56330e7-4a62-4da8-8082-67773b374b6a",
                "dc7ea4bd-8317-491c-adb2-64dd60610f4b",
                "eaa70339-39aa-4813-9b12-1d488de8fa22"
            ]
        },
        //post-OPF-RW-Traffic-FE
        {
            parentId: "bd07ae0a-0987-434d-abb4-c4a1d09b338e",
            childId: ["eedd078b-7eb7-4402-bce9-58bbb587fd13",
                "62484cba-c352-4936-a45c-9c13ebebc23f",
                "b877e9b2-7417-48bd-9922-26f178ed007a",
            ]
        },
    ]],
    [ProfileGroup.ShoppingPortalTotalTraffic, [
        //Shopping-Portal-Total-Traffic-NA
        {
            'parentId': 'bdfcf98a-d9e2-4cdb-9dd4-b28004a7b5e0',
            'childId': [
                '30ae7fef-df4e-4d08-b7ce-cf11987d25a7',
                '8ae9e78e-691c-44ed-97af-cd60060cc7a2',
                '49cec6fc-6e6a-4447-8625-011af5c1d694',
                '2f3742bd-a4e9-474c-9e2f-40b9e6af73b0'
            ]
        },
        //Shopping-Portal-Total-Traffic-EUIN
        {
            'parentId': '1875edda-b6c3-43d8-a152-5069140b05c1',
            'childId': [
                '258717dc-3813-482d-bbf4-2b39dc018295',
                '3ab857db-ecbf-4d9e-9092-a21369f8de6e',
                'f91fb1bd-f81d-4939-8aa7-4fb7c5dfc31d',
                '15f8a8d0-7558-4190-acd3-4de649c0a2f7']
        },
        //Shopping-Portal-Total-Traffic-FE
        {
            'parentId': '7649dd7f-85bc-45fd-b5c0-c246535eecf0',
            'childId': [
                '006238d8-219a-4807-b048-e12b0dbee2e3',
                'a3e9e91e-2c6e-4271-8fb7-b912a6ac8486',
                'c6687c82-14c4-4184-85cc-c33f4ce7f4ae',
                'f9668598-d163-42aa-9357-0eddffe84f9b',
            ]
        },
    ]],
    [ProfileGroup.ShoppingPortalSearchTraffic, [
        //Shopping-Portal-Search-Traffic-NA
        {
            "parentId": "2f3742bd-a4e9-474c-9e2f-40b9e6af73b0",
            "childId": [
                "fb318a4d-b207-42f1-97a3-8b722cf61f68",
                "3a700a93-e479-4088-bf41-755e6b1643aa",
                "bdead445-8e7c-4fbf-8ae8-aaf4613a0892",
                "d33377df-ad27-45eb-b561-fd0f01687301"
            ]
        },
        //Shopping-Portal-Search-Traffic-EUIN
        {
            'parentId': '15f8a8d0-7558-4190-acd3-4de649c0a2f7',
            'childId': ['aa8f1ff1-353b-4f18-811e-809c17d37a98',
                '61c3a640-b980-4400-a2af-1d155847538d',
                '7219190a-7b47-4ad1-a6e1-f2573c310941',
                'd19ed10c-f5dd-4d9f-ac74-5e5abcef5b89',
                '64e0c3ec-dcef-462c-bfec-ca78d135a5ae',
                'b1b5697f-da48-4a1d-8013-4887765fe300',
                '3f61aa76-a3e4-4e6d-b780-841c6d1cfb9d',
                'b4388a78-104b-4136-8307-27b77e8bae70',
                '0bf1387d-3883-4183-843d-3b6a0c4958b4',
                '6a119c02-378b-4114-bf40-0a27ee3883f5',
                '131f16eb-4b7b-429f-abe5-da2f686c668e',
                'd90b8836-431b-4cce-8c34-36fc0f34f35e']
        },
        //Shopping-Portal-Search-Traffic-FE
        {
            'parentId': 'f9668598-d163-42aa-9357-0eddffe84f9b',
            'childId': ['1b531473-1519-4411-9202-da5958ac2114',
                'c0571127-1d9c-4b08-8b4d-b32759775189',
                '6bd3b7be-7fb8-4d15-a4dc-feddea9c95a7']
        },
    ]],
    [ProfileGroup.ShoppingPortalGatewayTraffic, [
        //Shopping-Portal-Gateway-Traffic-NA
        {
            "parentId": "8ae9e78e-691c-44ed-97af-cd60060cc7a2",
            "childId": [
                "84c2e134-6a1b-4c57-9ae1-2100cc15103e",
                "1dfab9ce-6642-4d2c-8767-c27adbb8c47d",
                "e6c14637-9cc9-43ba-bdd7-1dd71504c55d",
                "6647a5bb-d7bb-44d3-b4d7-725300dbac08",
                "a5319c65-0a42-4cd0-875a-c8bae5c1506f",
                "49574077-5f8c-4477-b712-192b8785bed1",
                "2e9859f3-5944-4adc-a5c1-ad85b1ad1bd4",
                "78d22b1c-8722-4483-8713-b37cb7c06cd8",
                "fe00caa6-e323-497d-bf7c-dcac2be35f64",
                "ea9720a0-b2a1-4cea-8b96-595b317c199b"
            ]
        },
        //Shopping-Portal-Gateway-Traffic-EUIN
        {
            "parentId": "3ab857db-ecbf-4d9e-9092-a21369f8de6e",
            "childId": [
                "35998ec6-2485-48fc-a55d-be5f46dbb778",
                "e1a2596f-64cc-4114-b136-c11039498e73",
                "5fad36b4-6505-4caa-8d9a-266ac3059289",
                "08d75823-67b2-4c69-a24e-79de03835911",
                "ec0a2caa-1b49-432d-b819-e00f0279c60c",
                "84ede77b-0e41-4107-90b2-fab2e12281f9",
                "8d7641dd-d092-4b07-a1c5-eb6389874d16",
                "cc3e21cc-31ff-40fa-9e4b-bca828b6332b",
                "db0cf241-1624-45ef-8ca9-fa2b142399cc",
                "5bf4ae7a-7ce5-4859-86a6-393def4114b9",
                "eb7176d3-3948-4bcb-a3a4-016ce97f3bfe",
                "1d478af7-41e6-4bfe-b896-25ac6af0f830",
                "85b01631-8e12-4cf9-a873-6ba8881b0056",
                "99639687-6f3d-475f-b6e4-fa75d61f0b28",
                "fe0d8ce9-0195-46b3-9d19-7ebf41ed6fc9",
                "07636832-e8d2-4460-8dde-244f0df8aac2",
                "14674d96-b16e-46c5-9a0e-29aa29ae9bf5",
                "82cddf4b-2d59-47c3-b8e3-c6ef5aa6a221",
                "8faa3a06-c5c0-453b-b9ea-d695abb821db",
                "d3a5ff9b-793f-47ce-a160-133cab0582b4",
                "29403170-d20d-426d-ab90-4ef44a1d81ca",
                "d028e3f1-1e34-453e-a2d4-f0a6d0aa39a5",
                "1ca7c477-bf55-4b03-8a90-a1daae58d211",
                "37773628-4780-49aa-b446-603018d332fe",
                "35664911-a32f-4925-931d-938c546b026a",
                "9c0bf475-774b-4a99-a3ac-e976b4306c1f",
                "6ae9fd87-97ef-4d0c-80f5-25c2147b42ef",
                "5a3d3b73-0c19-41e4-b2e7-7b4c51622e53",
                "b78a4c47-4de8-4bd8-a6cb-47d65a653edc"
            ]
        },

        //Shopping-Portal-Gateway-Traffic-FE
        {
            "parentId": "a3e9e91e-2c6e-4271-8fb7-b912a6ac8486",
            "childId": [
                "e5922999-10df-45d4-9cec-c9dbf284e820",
                "9c9b128d-f884-4fd8-949b-ddc93a44b514",
                "ac1882ce-c508-4dd3-9518-404c5699b320",
                "bbf71e28-1044-446f-9520-793f572ed53b",
                "2b7b34b2-a15b-436a-8dab-e3fe5f441a0c",
                "7a526f1d-5046-499a-9770-c1aead3b8e4e",
                "9fedc6ba-6971-44c9-a0a2-ec67e6cbde41",
                "553e554b-f54c-49e7-9f78-38ac5ae06f72"
            ]
        }
    ]],
    [ProfileGroup.ShoppingPortalDetailTraffic, [
        //Shopping-Portal-Detail-Traffic-NA
        {
            "parentId": "30ae7fef-df4e-4d08-b7ce-cf11987d25a7",
            "childId": [
                "1debe29a-e272-4527-aab7-b677d7bad56a",
                "dbaa03b7-c4d2-49eb-8783-6fb4e222ede9",
                "5e4a5abf-5956-4be1-ac16-3e5ec34c1075",
                "b9138181-2e4a-4161-9852-1ca71b092c2c",
                "bf260344-b73d-460d-83d6-231a1a8e5cd5",
                "075d9d68-6c30-4b82-9232-434f61204ef3",
                "b86e9fcf-9f0f-473e-b451-6c096feaa751",
                "209e7391-691c-4837-a9e3-517d9d2f8470",
                "2bdd5831-5e5e-4e7f-bcb3-43031b69ca0c",
                "aaff90ac-04ed-4bd1-b558-aebae4a42e1f"
            ]
        },

        //Shopping-Portal-Detail-Traffic-EUIN
        {
            "parentId": "258717dc-3813-482d-bbf4-2b39dc018295",
            "childId": [
                "ef2e63c3-1256-412e-81a0-78b4fde4c2d7",
                "686d325b-2bf9-4d0d-810b-89c21489d537",
                "26476a04-8722-406f-a15e-29ae14e3d357",
                "e34c5935-5d91-42fb-8eaa-467118c6efa8",
                "750d49d9-fe48-4d86-9284-c3e5e9a94d0e",
                "0193b7f9-ee47-4551-ad43-4f036ca6ce91",
                "7ab671bc-21c8-4e81-815b-d392433f95a8",
                "0200e59d-8fc9-4a5e-89a4-06944b18597e",
                "25342829-64aa-496c-9732-dce9bacea4b6",
                "26babb8b-0f84-4ae2-a8c0-08101006ebc5",
                "74275eaf-3699-4160-babb-1c73bc9f828f",
                "3a8ba1b2-f4fe-4abb-a1eb-c5cacda3eed3",
                "b7652be5-0692-4f2c-811f-dfef324a0811",
                "25201e6c-9753-4d6a-92dc-6496d995b0e0",
                "47ec9804-2793-4892-8d73-b20bedb1fc20",
                "29f7fbf7-3b22-408b-9b11-970fdc598c82",
                "4addf417-fdaf-468b-ade0-0c352aa1c356",
                "e529c111-0bf1-4e28-a56e-cac294544867",
                "8759b614-42c3-491d-8d02-faa38591d6fa",
                "07e18a8f-c7d9-4786-8e81-dac0fb75124a",
                "e07af866-b47c-4588-bd62-86d98293f5cf",
                "8413415d-af8c-4e9b-95f8-d79e895bd4eb",
                "96c34f9a-ef86-484b-b483-def1416af39e"
            ]
        },
        //Shopping-Portal-Detail-Traffic-FE
        {
            "parentId": "006238d8-219a-4807-b048-e12b0dbee2e3",
            "childId": [
                "2200ed03-001d-40a4-8605-809e65375bea",
                "11912ca2-bbdd-43a9-ae3c-dc1aa4bf3830",
                "505e67d9-b285-4308-ad5d-0a1b343f3c6f",
                "3e235492-9634-4644-93b5-e600e74b7b54",
                "30e43fe6-f0a6-4c86-a576-cb88b3af43c1",
                "4b2de7ee-cec7-4056-9b49-ae7ee875db44",
                "2d9bb28c-aaa3-45e4-9638-023249ae8ccf",
                "904d875d-cd1d-44b5-9461-4adff576ee09"
            ]
        },
    ]],
    [ProfileGroup.DatapathResponder, [
        {
            "parentId": "30272409-f944-4409-9425-a02353130d9f",
            "childId": []
        },
        {
            "parentId": "cd1d7b68-b5b5-4ffe-ae91-f31d926bb862",
            "childId": []
        },
        {
            "parentId": "db762ab3-c578-4666-aa48-af5a1da95248",
            "childId": []
        },
        {
            "parentId": "63f2663c-96e8-489f-b3e3-b4b83f82fa85",
            "childId": []
        },
        {
            "parentId": "78ab4fdf-2123-4e5e-ae22-b27d676249a2",
            "childId": []
        },
        {
            "parentId": "5ccfda66-44de-4acf-bd1c-9cf732d36c97",
            "childId": []
        }
    ]],
    [ProfileGroup.SharedHttp, [
        {
            "parentId": "4ce8db17-38d6-4639-b2a0-ebef729503b8",
            "childId": []
        },
        {
            "parentId": "07cae5b1-c949-48e4-b121-fdca8a9d5673",
            "childId": []
        },

        {
            "parentId": "c19b93ed-edd9-485f-b95e-2da5d923882c",
            "childId": []
        }
    ]],
    [ProfileGroup.AAPIService, [
        {
            "parentId": "54c63e08-f56a-4aca-b4d5-2a0eb6329fdf",
            "childId": []
        },
        {
            "parentId": "74e1223c-cb66-4638-adca-dd03f7e9ae05",
            "childId": []
        },
        {
            "parentId": "949571a6-b1d7-43c6-bfea-1b2e02ebe3a8",
            "childId": []
        }
    ]],
    [ProfileGroup.AmazonAPIAPIDeals, [
      {
        "parentId": "a4897908-9fd5-4ca2-ae76-603118dc2d62",
        "childId": []
      },
      {
        "parentId": "e16a2a2f-4991-4ea9-8c42-8c387e9e7d9a",
        "childId": []
      },
      {
        "parentId": "248529a3-f2c0-4e03-af7b-8e8c87fafb37",
        "childId": []
      }
    ]],
    [ProfileGroup.AmazonAPIAPINonDeals, [
        {
            "parentId": "538e4d36-394a-4245-aa3d-a01a648f8eb4",
            "childId": []
        },
        {
            "parentId": "78f6d30e-ed41-4203-b575-e5be8e82ad39",
            "childId": []
        },
        {
            "parentId": "c6343c7b-9442-4111-b927-a6b2fc94fdb1",
            "childId": []
        }
    ]],
    [ProfileGroup.AmazonAPIExternal, [
        {
            "parentId": "9c13f67d-2c52-4b78-8df1-4cd639b13727",
            "childId": []
        },
        {
            "parentId": "a52ee914-71da-4e13-a31a-bbd20f05c56f",
            "childId": []
        },
        {
            "parentId": "f46a07a1-a4bc-4782-a7eb-dafffb6cfc20",
            "childId": []
        }
    ]],
    [ProfileGroup.AmazonAPIExternalAPIDeals, [
      {
        "parentId": "62491b0f-2931-4430-b0d9-91d02264ae66",
        "childId": []
      },
      {
        "parentId": "81db0204-62ab-4d52-b553-efd2f0c23b2e",
        "childId": []
      },
      {
        "parentId": "a5ec666c-f7b9-4e7d-976b-b4a7cabd58e0",
        "childId": []
      }
    ]],
    [ProfileGroup.AmazonAPIExternalAPIOthers, [
      {
        "parentId": "cb56e9c0-1e17-4241-98d8-9b746e9ab92b",
        "childId": []
      },
      {
        "parentId": "667c79d8-cea6-4c57-bef7-be49bcae79df",
        "childId": []
      },
      {
        "parentId": "e1d01ff8-f4f8-425f-8570-583b3057b31a",
        "childId": []
      }
    ]],
    [ProfileGroup.AmazonAPIProductV2, [
      {
        "parentId": "2855a0f1-57f1-4c73-9cfa-1d65b4758d30",
        "childId": []
      },
      {
        "parentId": "7fca6eb8-5597-4f58-8581-3ecf84e16af9",
        "childId": []
      },
      {
        "parentId": "ee7273d0-b5ff-4cd1-b734-588c5b68c258",
        "childId": []
      }
    ]],
    [ProfileGroup.AmazonAPICanonicalProductV2, [
      {
        "parentId": "9acbb9b5-b7b3-4b22-838f-9493ca0bc8e5",
        "childId": []
      },
      {
        "parentId": "3a4105cb-5b67-46ad-95b7-7d3a1e0a420c",
        "childId": []
      },
      {
        "parentId": "0987ddff-eba8-4d7d-ad29-84bfb0bf40f1",
        "childId": []
      }
    ]],
    [ProfileGroup.AmazonAPICustomerContext, [
        {
            "parentId": "8d0bbae1-d0a9-48cf-b0e8-42fcd42fa132",
            "childId": []
        },
        {
            "parentId": "2a334c11-06f3-4137-841d-c7e330ff8483",
            "childId": []
        },
        {
            "parentId": "78cd7b50-6d66-4e37-8e9e-ca13718e90b0",
            "childId": []
        }
    ]],

    [ProfileGroup.AmazonAPIBuyingOptionsContextV2, [
      {
        "parentId": "55e6a210-28a6-4cc0-b122-492b26db1055",
        "childId": []
      },
      {
        "parentId": "0610175e-055f-4cf9-baf5-d88b5a20fbc5",
        "childId": []
      },
      {
        "parentId": "83bbed00-9021-48a5-896f-f8f9d6ac2dbd",
        "childId": []
      }
    ]],
    [ProfileGroup.AmazonAPIOfferComparisonExperience, [
      {
        "parentId": "3c635d7e-09a8-4af2-b079-56faae8128f5",
        "childId": []
      },
      {
        "parentId": "69228fae-f78a-4d17-a8db-1e481c4405f7",
        "childId": []
      },
      {
        "parentId": "2051d910-d1f7-443e-8a07-03d3e02ee347",
        "childId": []
      }
    ]],
    [ProfileGroup.AmazonAPIOthers, [
      {
        "parentId": "bcb1855a-db6f-4a6e-adc2-0dafe19fb13e",
        "childId": []
      },
      {
        "parentId": "7c9043a6-1995-4e09-aff8-29c1760fab99",
        "childId": []
      },
      {
        "parentId": "a777692d-4d01-46b0-9063-49cdb0fd8a64",
        "childId": []
      }
    ]],

    [ProfileGroup.AmazonApiDatapath, [
        {
            "parentId": "8aa7d380-5524-4bb7-bde3-d07df132ee7f",
            "childId": []
        },
        {
            "parentId": "db1a387f-63da-453d-a264-a800862d99fd",
            "childId": []
        },
        {
            "parentId": "b564f91b-f75a-400b-8a31-401bbbeaed26",
            "childId": []
        },
        {
            "parentId": "e261b976-1018-42a0-a5c0-177e644ef097",
            "childId": []
        },
        {
            "parentId": "e62bcb98-0250-49af-908b-1fed6ecd1fb1",
            "childId": []
        },
        {
            "parentId": "7ac9de39-7601-4608-957a-75ceb5b66c81",
            "childId": []
        }
    ]],
    [ProfileGroup.AmazonLastMile, [
        {
            "parentId": "c8c9b28f-767a-4374-88d0-cf287e2e69bd",
            "childId": []
        },
        {
            "parentId": "66b2b9e0-a1eb-455e-ba25-31f0e1af1796",
            "childId": []
        },
        {
            "parentId": "52ad7165-3a00-4f91-9ad0-ad18f9bcf749",
            "childId": []
        },
        {
            "parentId": "98db2515-bd77-4ea5-98ed-b89f001ba676",
            "childId": []
        }
    ]],
    [ProfileGroup.AFTInboundTransship, [
        {
            "parentId": "9d46e181-f098-42e9-b963-964552bdb176",
            "childId": []
        },
        {
            "parentId": "33dfb711-6295-4317-b1ed-69a96c17a63d",
            "childId": []
        },
        {
            "parentId": "d978f744-428f-45c4-ac5e-6c0f048d9dc4",
            "childId": []
        }
    ]],
    [ProfileGroup.AFTInboundReceive, [
        {
            "parentId": "f445cd52-d9f9-4b9c-80a0-80b85b0a8923",
            "childId": []
        },
        {
            "parentId": "c168505e-54ef-419b-9733-14ea9cc22fe8",
            "childId": []
        },
        {
            "parentId": "f35bcb07-21c0-4806-8dc7-6a6fa6b6962a",
            "childId": []
        }
    ]],
    [ProfileGroup.AFTOutboundTransship, [
        {
            "parentId": "35488462-96d9-4c34-8130-d13c6cc683f5",
            "childId": []
        },
        {
            "parentId": "52a6634d-690a-479a-8ab2-fe5bb0f7f60f",
            "childId": []
        },
        {
            "parentId": "ed204855-bc42-4518-8487-2832d80008d8",
            "childId": []
        }
    ]],
    [ProfileGroup.AFTOutboundCustomerShip, [
        {
            "parentId": "463a1715-8dc6-4332-ba00-7198642145b6",
            "childId": []
        },
        {
            "parentId": "9ee7dcf4-d1bb-4da5-af3e-5f46744f97d1",
            "childId": []
        },
        {
            "parentId": "d35bee81-6778-4b59-92f2-9535577dc991",
            "childId": []
        },
        {
            "parentId": "504f2f98-a05c-4c27-ac04-a555b74bc00b",
            "childId": []
        }
    ]]

])

export const PROFILE_GROUP_MAPPING = () => {
    return ALL_MAPPINGS
}


