import React, {useState} from "react";
import Container from "@amzn/awsui-components-react/polaris/container";
import {ColumnLayout, ExpandableSection} from "@amzn/awsui-components-react";
import ForecastChart, {ForecastChartProps} from "../chart/ForecastChart";
import {ParentToChildMapping} from "../../config/ProfileGroupMappings";
import {ChartData} from "../../live-monitoring/generated-src";
import {ActionableCollectionCardProps, CollectionCardProps} from "./ChartCollection";

export interface StaticDualExpandableChartProps {
    leftMapping?: ParentToChildMapping;
    rightMapping?: ParentToChildMapping
}

export interface DualExpandableChartProps extends StaticDualExpandableChartProps,
    ActionableCollectionCardProps,
    CollectionCardProps,
    ForecastChartProps {
}

export default function DualExpandableChartContainer(props: DualExpandableChartProps) {
    const [showChildren, setShowChildren] = useState(false);
    const leftChildren = props.leftMapping?.childId || []
    const rightChildren = props.rightMapping?.childId || []

    let childChartData: Map<string, (ChartData | undefined)> = new Map(
        [...leftChildren, ...rightChildren].map(id => [id, props.chartDataMap.get(id)])
    )


    const mapChildrenDataToForecastChart = (children) => {
        props.sortByExplicitRegion(children)
        const results = children.filter(child => childChartData.get(child))
            .map(child =>
                <ForecastChart
                    {...props}
                    chartData={childChartData.get(child)}
                    key={child}/>
            )
        return results

    }


    const leftChartData = props.chartDataMap.get(props.leftMapping?.parentId || "");
    const rightChartData = props.chartDataMap.get(props.rightMapping?.parentId || "");

    return (
        <Container
            footer={
                childChartData.size !== 0 &&
                <ExpandableSection
                    header="Expand to Individual Forecast Profiles"
                    variant="footer"
                    expanded={showChildren}
                    onChange={expanded => {
                        if (expanded) {
                            props.addActiveCharts([...leftChildren, ...rightChildren])
                        } else {
                            props.removeCharts([...leftChildren, ...rightChildren])
                        }
                        setShowChildren(expanded.detail.expanded)
                    }}>
                    <ColumnLayout variant={"text-grid"} columns={2}>
                        {showChildren &&
                            <>
                                <div>
                                    {mapChildrenDataToForecastChart(leftChildren)}
                                </div>
                                <div>
                                    {mapChildrenDataToForecastChart(rightChildren)}
                                </div>
                            </>

                        }
                    </ColumnLayout>
                </ExpandableSection>
            }>
            <ColumnLayout variant={"text-grid"} columns={2}>
                {props.leftMapping && <ForecastChart {...props} chartData={leftChartData}/>}
                {props.rightMapping && <ForecastChart {...props} chartData={rightChartData}/>}
            </ColumnLayout>
        </Container>
    )
}